<template>
  <div class="form" :style="{height: height}">
    <el-form
        style="display: flex; flex-wrap: wrap; padding-top: 40px"
        :hide-required-asterisk="true"
        :label-width="formLabelWidth"
    >
      <!-- 前六个 -->
      <el-form-item label="文献类型" prop="title" :label-width="formLabelWidth">
        <el-select
            v-model="addGenealogyForm.bookDto.documentType"
            placeholder="请选择内容"
            filterable
            allow-create
            style="width: 100%"
        >
          <el-option
              v-for="item in $store.getters.getDocumentTypeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="题名" prop="title" :label-width="formLabelWidth">
        <el-input
            style="width: 100%"
            autocomplete="off"
            placeholder="请输入内容"
            v-model="addGenealogyForm.bookDto.title"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="责任者" prop="contributor" :label-width="formLabelWidth">
        <el-input
            style="width: 100%"
            autocomplete="off"
            placeholder="请输入内容"
            v-model="addGenealogyForm.bookDto.contributor"
        ></el-input>
      </el-form-item>
      <el-form-item label="出版地" prop="placeOfPublication" :label-width="formLabelWidth">
        <el-input
            style="width: 100%"
            autocomplete="off"
            placeholder="请输入内容"
            v-model="addGenealogyForm.bookDto.placeOfPublication"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="出版者" prop="publisher" :label-width="formLabelWidth">
        <el-input
            style="width: 100%"
            autocomplete="off"
            placeholder="请输入内容"
            v-model="addGenealogyForm.bookDto.publisher"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="出版时间" prop="dateOfPublication" :label-width="formLabelWidth">
        <el-input
            style="width: 100%"
            autocomplete="off"
            placeholder="请输入内容"
            v-model="addGenealogyForm.bookDto.dateOfPublication"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="页码" prop="extent" :label-width="formLabelWidth">
        <el-input
            style="width: 100%"
            autocomplete="off"
            placeholder="请输入内容"
            v-model="addGenealogyForm.bookDto.extent"
        >
        </el-input>
      </el-form-item>
      <!-- <div style="height: 1px; width: 100%; color: #ccc; text-align: center;margin-bottom: 10px;">
        ------------<a href="javascript:;" @click="$emit('update:isLoadMore', !isLoadMore)">查看更多</a>------------
      </div> -->
      <template v-if="isLoadMore">
        <el-form-item :label="v.val" :prop="v.label" v-for="(v, i) in tempField" :key="i" :label-width="formLabelWidth">
          <el-input
            style="width: 100%"
            autocomplete="off"
            placeholder="请输入内容"
            v-model="v[v.label]"
          />
        </el-form-item>
        <el-form-item label="其他题名" prop="alternative" :label-width="formLabelWidth">
        <el-input
            style="width: 100%"
            autocomplete="off"
            placeholder="请输入内容"
            v-model="addGenealogyForm.bookDto.alternative"
        >
        </el-input>
      </el-form-item>
          <el-form-item label="责任方式" prop="role" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.role"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="中图分类" prop="classification" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.classification"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="主题" prop="keyword" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.keyword"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="附注" prop="notes" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.notes"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="内容形式" prop="contentForm" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.contentForm"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="媒体类型" prop="mediaType" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.mediaType"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="格式" prop="format" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.format"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="语种" prop="language" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.language"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="关联" prop="relation" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.relation"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="时间范围" prop="temporal" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.temporal"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="空间范围" prop="spatial" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.spatial"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="版本" prop="edition" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.edition"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="适用对象" prop="audience" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.audience"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="ISBN" prop="isbn" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.isbn"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="统一书号" prop="unionBooksNumber" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.unionBooksNumber"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="CDOI" prop="cdoi" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.cdoi"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="发布地址" prop="uri" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.uri"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="数据提交单位" prop="owner" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.owner"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="所属任务年份" prop="recordDate" :label-width="formLabelWidth">
            <el-input
                style="width: 100%"
                autocomplete="off"
                placeholder="请输入内容"
                v-model="addGenealogyForm.bookDto.recordDate"
            >
            </el-input>
          </el-form-item>
        </template>


      <!--      <span title="添加字段" class="addField" @click="addField">-->
      <!--        <i class="el-icon-circle-plus add-icon"></i>-->
      <!--      </span>-->
      <!--      <el-form-item-->
      <!--          label="描述"-->
      <!--          prop="abstract"-->
      <!--          :label-width="formLabelWidth"-->
      <!--          style="width: 100%"-->
      <!--      >-->
      <!--        <el-input-->
      <!--            type="textarea"-->
      <!--            :rows="3"-->
      <!--            style="width: 90%"-->
      <!--            autocomplete="off"-->
      <!--            placeholder="请输入资源内容的解释"-->
      <!--            v-model="addGenealogyForm.bookDto.abstract"-->
      <!--        >-->
      <!--        </el-input>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item-->
      <!--          v-for="(domain, index) in addFieldObj"-->
      <!--          :label="domain.fieldChinaName"-->
      <!--          :key="index"-->
      <!--          :prop="domain.fieldValue"-->
      <!--      >-->
      <!--        <el-input v-model="domain.fieldValue" autocomplete="off"></el-input>-->
      <!--        <div class="filedspan">-->
      <!--          <span @click="deleteFiled(index)">-->
      <!--            <a title="删除此字段"><i class="el-icon-delete"></i></a>-->
      <!--          </span>-->
      <!--        </div>-->
      <!--      </el-form-item>-->
    </el-form>
    <div class="btn-bottom" v-show="isBtn">
      <el-button v-if="!isLoadMore" @click="$emit('update:isLoadMore',!isLoadMore)" type="primary" style="width: 200px">加载更多字段</el-button>
      <el-button v-else @click="$emit('update:isLoadMore',!isLoadMore)" type="primary" style="width: 200px">关闭加载更多</el-button>
      <!-- <el-button
          type="primary"
          style="width: 200px; margin-left: 40px"
          @click="SavaImgToDocument"
      >送入识别<i class="el-icon-upload el-icon--right"></i
      ></el-button> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formLabelWidth: {
      type: String,
      required: true
    },
    isBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    height: {
      type: String,
      required: false,
      default: '350px'
    },
    isLoadMore: {
      type: Boolean,
      required: true,
    },
   
  },
  data() {
    return {
      addGenealogyForm: {
        bookDto: {
          documentTypeText: '', // 字符串文件类型
          title: "", // 题名
          sourceID: "", // 加工资源ID
          owner: "", // 数据提交单位
          documentType: 134, //文献类型
          contributor: "", //责任者
          keyword: "", //主题
          abstract: "", //描述（摘要）
          publisher: "", //出版者名称
          dateOfPublication: "", //出版日期
          classification: "口", //中图分类
          contentForm: "口", //内容形式
          mediaType: "口", //媒体类型
          format: "口", //格式
          language: "chi", //语种
          audience: "口", //适用对象
          recordDate: "口", // 所属任务年份
          placeOfPublication: '', // 出版地
          extent: '', // 页码
          alternative: '', // 其他题名
          role: '', // 责任方式
          notes: '', // 附注
          relation: '', // 关联
          temporal: '', // 时间范围
          spatial: '', // 空间范围
          edition: '', // 版本
          isbn: '', // isbn
          unionBooksNumber: '', // 统一书号
          cdoi: '', // cdoi
          uri: '', // 发布地址
        },
        pictureInfoDtos: [],
      },
      tempField: [],
      resetTempFidld: null,
    }
  },
  methods: {
    // 送入识别
    SavaImgToDocument() {
      this.$emit('SavaImgToDocument', this.addGenealogyForm, this.tempField)
    },
    // 删除字段
    deleteFiled() {

    },
    // 添加字段
    addField() {

    },
    // 设置文献特有字段
    setTempField(value) {
      switch(value) {
        // 古籍
        case 134:
          this.resetTempFidld = JSON.parse(JSON.stringify(this.$store.state.ancientWorks))
          this.tempField = JSON.parse(JSON.stringify(this.$store.state.ancientWorks))
          break;
          // 谱牒
          case 133:
            this.resetTempFidld = JSON.parse(JSON.stringify(this.$store.state.genealogy))
            this.tempField = JSON.parse(JSON.stringify(this.$store.state.genealogy))
          break;
          // 方志
          case 131:
            this.resetTempFidld = JSON.parse(JSON.stringify(this.$store.state.localRecords))
            this.tempField = JSON.parse(JSON.stringify(this.$store.state.localRecords))
          break;
          // 历史档案
          case 132:
            this.resetTempFidld = JSON.parse(JSON.stringify(this.$store.state.historicalArchives))
            this.tempField = JSON.parse(JSON.stringify(this.$store.state.historicalArchives))
          break;
        // 图书
        case 100:
          this.tempField = []
        break;
      }
    },
    // 清空表单
    emptyForm() {
      const emptyList = JSON.stringify(this.addGenealogyForm)
      this.addGenealogyForm = JSON.parse(emptyList)
      this.tempField = this.resetTempFidld
    }
  },
  watch: {
    'addGenealogyForm.bookDto.documentType' : {
        handler(value) {
          this.setTempField(value)
        },
        immediate: true
    }
  }
};
</script>

<style scoped lang="less">

.form {
  overflow: auto;
  .btn-bottom{
    position: absolute;
    bottom: 0;
    left: 25%;
    transform: translateX(-50%);
  }
}
</style>
