import axios from 'axios'
import router from 'router'
import { Message } from 'element-ui'

//axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
export function request(config) {
    const instance = axios.create({
        baseURL: '',
        timeout: 15000,
        withCredential: false, // 是否允许带cookie这些
        headers: {
            //"Authorization": db.get("TOKEN"),
            "Authorization": this.$cookies.get("TOKEN"),
            "accept": "text/plain",
            "Content-Type": "application/json",
            // 'set-Cookie': 'SameSite=None; Secure'
            // "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            //'Content-Type': 'application/x-www-form-urlencoded'
        },
    });

    // 请求拦截器
    // instance.interceptors.request.use(
    //     config => {
    //         Toast.loading({
    //             message: "加载中...",
    //             forbidClick: true,
    //         });
    //         console.log(config, 'config')
    //         return config;
    //     },
    //     error => {
    //         tryHideFullScreenLoading()
    //         Promise.reject(error)
    //     }
    // )

    // instance.interceptors.request.use(
    //     config => {
    //         console.log(config, 'config')

    //         // const keys = this.$cookies.keys()
    //         // keys.forEach(v => {
    //         //     this.$cookies.remove(v)
    //         // });

    //         return config;
    //     },
    //     error => {
    //         tryHideFullScreenLoading()
    //         Promise.reject(error)
    //     }
    // )

    instance.interceptors.response.use(
        response => {
            // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
            // 否则的话抛出错误
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
            /* else if (response.status === 204) {
                           Message({
                               message: '已注销登录',
                               duration: 1500,
                               type: "success",
                               forbidClick: true
                           });
                           db.remove("USER");
                           db.remove("TOKEN");
                           db.remove("USER_ROUTER");
                           router.replace({
                               path: '/',
                               query: {
                                   redirect: router.currentRoute.fullPath
                               }
                           });
                       } */
            else {
                return Promise.resolve(response);
                return Promise.reject(response);
            }
        },
        // 根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
        error => {
            if (error.message.includes('timeout')) { // 判断请求异常信息中是否含有超时timeout字符串
                console.log("错误回调", error);
                Message({
                    message: '请求超时',
                    duration: 2000,
                    forbidClick: true
                });
                return Promise.reject(error); // reject这个错误信息
            }
            if (error.response.status) {
                switch (error.response.status) {
                    case 401:
                        Message({
                            message: '登录已过期,请重新登录',
                            duration: 2000,
                            forbidClick: true
                        });
                        this.$cookies.remove("USER");
                        this.$cookies.remove("TOKEN");
                        router.replace({
                            path: '/login',
                            query: {
                                redirect: router.currentRoute.query.redirect ? router.currentRoute.query.redirect : router.currentRoute.fullPath
                            }
                        });
                        break;
                    case 403:
                        Message({
                            message: error.response.data.error.message,
                            duration: 2000,
                            forbidClick: true
                        });
                        break;
                    case 404:
                        Message({
                            message: '网络请求不存在',
                            duration: 2000,
                            forbidClick: true
                        });
                        break;
                    case 405:
                        Message({
                            message: 'Method not allowed',
                            duration: 2000,
                            forbidClick: true
                        });
                        break;
                    case 500:
                        Message({
                            message: '服务器异常',
                            duration: 2000,
                            forbidClick: true
                        });
                        break;
                    case 502:
                        // 清空所有cookie
                        const keys = this.$cookies.keys()
                        keys.forEach(v => {
                            this.$cookies.remove(v)
                        });
                        break;
                        // 其他错误，直接抛出错误提示
                    default:
                        Message({
                            message: error.response.data.error.message,
                            duration: 1500,
                            forbidClick: true
                        });
                }
                return Promise.reject(error.response.data);
            }
        }
    );
    return instance(config)
}
