import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
import store from './store'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/icon.css'; //element-ui 图标库
import { Uploader, Button, Grid, GridItem, Image as VanImage,Icon} from 'vant';
import 'vant/lib/index.css';
import { request } from "./network/request.js"
import './network/directives'

Vue.config.productionTip = false
Vue.prototype.$request = request

Vue.prototype.baseURL = "/"

Vue.use(ElementUI)
Vue.use(VueCookies)
Vue.use(Uploader)
Vue.use(Button)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(VanImage)
Vue.use(Icon)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
