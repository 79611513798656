<template>
<div>
        <router-view name="routerHome"></router-view></div>
</template>
<script>

export default {
  name: "App",
  components: {
  },
  data() {
    return {
      activeIndex: '1',
      screenWidth: document.body.offsetWidth,
      screenHeight: document.documentElement.clientHeight,
    };
  },
  computed: {
    bodyHeight() {
      return this.screenHeight
    },
  },
  methods: {
    handleSelect(tab, event) {
      console.log(tab);
      this.$router.push(tab.name)
    }
  },
  mounted: function () {
    const that = this
    // 自适应布局
    window.onresize = () => {
      return (() => {
        that.screenWidth = document.body.offsetWidth
        that.screenHeight = document.documentElement.clientHeight
      })()
    }
  }
};
</script>

<style>
@import url("~@assets/css/basecss.css");
@import url("~@assets/css/index.css");
@import url("~@assets/css/iconfont.css");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.el-header,
.el-footer {
  box-sizing: border-box;
  height: 60px;
  background: white;
  line-height: 60px;
}

.el-footer {
  text-align: center;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #f2f2f2;
  /*color: #333;
  text-align: center;*/
}

body>.el-container {
  margin-bottom: 0px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>
