<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-card class="card" style="height: 99%">
          <el-row>
            <el-col :span="24">
              <div class="file-image" style="padding: 0">
                <el-row>
                  <!-- 左边 -->
                  <el-col :span="12">
                    <div class="test-class">
                      <div class="text item" style="text-align: center">
                        <div class="upload-code">
                          <el-upload
                            class="upload-demo"
                            drag
                            action="upLoadBaseURL+'/api/fileinfo/file/save-file/1?sourceID=130013020220000001&volumeNo=1'"
                            :on-success="onSuccess"
                            :on-error="onError"
                            :on-change="handleChange"
                            :on-exceed="handleExceed"
                            multiple
                            :file-list="fileList"
                            :auto-upload="false"
                            :with-credentials="true"
                            :show-file-list="false"
                            :limit="limit"
                            accept=".jpg,.png,.tif,.jpeg,.JPG,.PNG,.TIF,.JPEG,.zip"
                          >
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                              将文件拖到此处，或<em>点击选择</em>
                            </div>
                          </el-upload>
                          <div class="image">
                            <!--<img src="../assets/images/ch.png" style="width: 150px; height:150px"/>-->
                            <vue-qr :text="imgUrl" :size="140" :logoScale="0.2">
                            </vue-qr>
                            <span>扫一扫，上传图片</span>
                          </div>
                        </div>
                        <div class="el-upload__tip" slot="tip">
                          <nobr>
                            图片支持png、jpg、jpeg、tif等格式，大小不超过10M；压缩包仅支持zip格式。剩余识别页数：{{
                              $store.getters.getUserInfo.identifyCount
                            }}
                          </nobr>
                        </div>
                      </div>
                      <field-form
                        formLabelWidth="130px"
                        ref="fieldForm"
                        :isLoadMore.sync="isLoadMore"
                      />
                    </div>
                  </el-col>
                  <!-- 右边 -->
                  <el-col
                    :span="12"
                    style="padding-left: 10px; height: 560px"
                    class="col-left"
                    id="col-right"
                  >
                    <div style="display: flex; justify-content: space-between">
                      <div>
                        <el-button
                          type="primary"
                          style="width: 200px"
                          @click="submitUpLoadNew"
                          >上传图片<i
                            class="el-icon-upload el-icon--right"
                          ></i></el-button
                        >已选择：{{ fileList.length }} 张
                      </div>
                      <el-button
                        type="primary"
                        style="width: 200px; margin-left: 40px"
                        @click="SavaImgToDocument"
                        >送入识别<i class="el-icon-upload el-icon--right"></i
                      ></el-button>
                    </div>
                    <!-- 未上传 -->
                    <ul class="u-list not-uploaded" v-show="isFileListShow">
                      <li
                        class="u-li notUploaded"
                        v-for="(item, index) in fileList"
                        :key="item.uid"
                      >
                        <el-image
                          :src="item.url"
                          :alt="item.name"
                          fit="scale-down"
                        ></el-image>
                        <div class="mask"></div>
                        <div
                          style="width: 100%; display: flex; overflow: hidden"
                          :title="item.name"
                        >
                          <p
                            style="
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              flex: 1;
                              text-align: center;
                            "
                          >
                            {{ item.name }}
                          </p>
                        </div>
                        <span class="upload-list-icon">
                          <span
                            @click="
                              handlePreview(item.url, index, 'notUploaded')
                            "
                            title="查看"
                          >
                            <i class="el-icon-zoom-in" style="color: #ddd"></i>
                          </span>
                          <span @click="handleRemove(index)" title="删除">
                            <i
                              class="el-icon-delete"
                              style="color: #f56c6c"
                            ></i>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <!-- 上传完成 -->
                    <ul class="u-list">
                      <li
                        class="u-li"
                        v-for="(item, index) in uploadImageList"
                        :key="item.id"
                      >
                        <el-image
                          :src="
                            `${baseurl}api/fileinfo/image/GetOriImage?filename=${item.pictureFullName}&ocrSource=1` +
                            timestamp
                          "
                          :alt="item.pictureFileName"
                          fit="scale-down"
                        ></el-image>
                        <div
                          style="width: 100%; display: flex; overflow: hidden"
                          :title="item.pictureFileName"
                        >
                          <p
                            style="
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              flex: 1;
                              text-align: center;
                            "
                          >
                            {{ item.pictureFileName }}
                          </p>
                        </div>
                        <span class="upload-list-icon">
                          <span
                            @click="handlePreview(item, index, 'upload')"
                            title="查看"
                          >
                            <i class="el-icon-zoom-in" style="color: #ddd"></i>
                          </span>
                          <span
                            @click="handleImgRemove(item.id, index)"
                            title="删除"
                          >
                            <i
                              class="el-icon-delete"
                              style="color: #f56c6c"
                            ></i>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <div class="pagination-bottom">
                      <!-- @size-change="handleSizeChange" -->
                      <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="imgPageIndex"
                        :page-size="imgPageSize"
                        prev-text="上一页"
                        next-text="下一页"
                        background
                        layout="total, prev, pager, next"
                        :total="imgTotals"
                      >
                      </el-pagination>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </el-card>
        <el-dialog
          :visible.sync="previewDialogVisible"
          class="dialog"
          :modal="true"
          :close-on-click-modal="false"
          title="图片预览"
          @close="onCancel"
          :lock-scroll="true"
          :append-to-body="true"
        >
          <div class="operation" v-if="this.dialogStatus">
            <el-button type="" @click="onRotate('-90')"
              >逆时针旋转90°</el-button
            >
            <el-button type="" @click="onRotate('90')">顺时针旋转90°</el-button>
            <!-- <el-button @click="onCut">裁剪</el-button> -->
          </div>
          <div class="imgBox" ref="imgBox">
            <img
              ref="cutImg"
              class="imgBox-img"
              :src="previewDialogImageUrl"
              alt=""
            />
          </div>
          <el-row
            slot="footer"
            type="flex"
            justify="center"
            v-if="this.dialogStatus"
          >
            <!-- 列被分为24 -->
            <el-col :span="6">
              <el-button size="medium" @click="onCancel">取消</el-button>
              <el-button type="primary" size="medium" @click="onConfirm"
                >确定</el-button
              >
            </el-col>
          </el-row>
        </el-dialog>
        <!-- 支付弹框 -->
        <el-dialog
          :title="payInfo.title"
          :visible.sync="payDialog"
          width="30%"
          center
          v-if="payDialog"
          class="pay-dialog"
          @close="onPayDialogClose"
        >
          <div class="pay-dialog-main">
            <vue-qr
              :text="payInfo.payUrl"
              :size="200"
              :logoScale="0.2"
              class="pay-code"
            ></vue-qr>
            <p class="pay-remark">
              {{ payInfo.remark }}<i class="money">￥{{ payInfo.payMoney }}</i>
            </p>
          </div>
        </el-dialog>
        <!-- 新增字段弹框 -->
        <add-field
          :is-show.sync="isAddFieldDialogShow"
          @updateForm="updateForm"
          :filed-form="filedForm"
          @minusOne="minusOne"
        />
      </el-col>
    </el-row>
    <el-dialog
      title="绑定手机号"
      :visible.sync="ipDialogVisible"
      width="30%"
      :before-close="close"
    >
      <el-form
        ref="ipForm"
        label-position="top"
        :model="ipForm"
        :rules="ipRules"
        label-width="60px"
      >
        <el-form-item
          label="ip登录用户不能保存历史识别结果，请输入手机号进行绑定存储历史识别结果"
          prop="Phone"
        >
          <el-input
            placeholder="请输入手机号"
            v-model="ipForm.Phone"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="onConfirmIpPhone">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import { Loading } from "element-ui";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import VueQr from "vue-qr";
import _ from "lodash";
import AddField from "@/components/AddField";
import FieldForm from "@/components/fieldForm";
export default {
  components: {
    FieldForm,
    AddField,
    VueQr,
  },
  data() {
    return {
      loadingInstance: null,
      imgArr: [],
      zipArr: [],
      ipForm: {
        Phone: "",
      },
      ipRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern:
              /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/,
            message: "请输入正确的号码格式",
            trigger: "blur",
          },
        ],
      },
      ipDialogVisible: false,
      parameter: null, // 充值后字段参数参数
      isLoadMore: false,
      frequency: 0,
      filedForm: {
        fieldEnglishName: "",
        fieldChinaName: "",
        fieldValue: "",
      },
      isAddFieldDialogShow: false,
      addFieldObj: [],
      payTimer: null,
      payInfo: {
        title: "", // 标题
        payUrl: "", // 支付链接
        remark: "", // 相关的信息
        payMoney: "", //金额
      },
      payDialog: false, // 支付弹框
      // elImgRef: null,
      isFileListShow: true,
      uploadThreadCount: 2, //2个线程
      zipUploadThreadCount: 2,
      uploadManyCount: 5,
      requestDegrees: 0, // 发请求的旋转角度
      imgBoxWidth: null,
      rotateImgWidth: null, // 旋转图片原始宽
      rotateImgHeight: null,
      timestamp: "", // 时间挫，生成唯一数字，解决浏览器缓存
      currentImageInfo: null,
      dialogStatus: false, // 是否是上传
      operation: null, // 1:旋转 3: 裁剪
      currentIndex: null,
      cropper: null,
      imgUrl: "",
      formLabelWidth: "110px",
      fileList: [], //上传图片集合
      limit: Infinity, // 不限制
      previewDialogImageUrl: "",
      baseurl: this.baseURL,
      previewDialogVisible: false, // 图片预览弹框
      addGenealogyForm: null,
      DocumentTypeList: [],
      value: "图书",
      /* 图片分页 */
      imgPageIndex: 1,
      imgPageSize: 15, // 每页显示多少条
      imgTotals: 0,
      uploadImageList: [],
    };
  },
  computed: {
    fileListLength() {
      return this.fileList.length;
    },
  },
  watch: {
    // 未上传数组的长度
    fileListLength(newVal) {
      if (newVal > 5) {
        this.imgPageSize = 5;
      } else if (newVal > 0 && newVal <= 5) {
        this.imgPageSize = 10;
      } else {
        this.imgPageSize = 15;
      }
      this.GetTempImages();
    },
  },
  created() {
    if (this.$store.state.ipLogin && !this.$store.state.ipPhone) {
      this.ipDialogVisible = true;
    }
    this.GetTempImages();
    this.Timer = setInterval(() => {
      this.GetTempImages();
    }, 2000);
  },
  mounted() {
    if (this.$store.getters.getUserInfo.identifyCount < 8) {
      this.limit = this.$store.getters.getUserInfo.identifyCount;
    }
    this.imgUrl =
      "http://" +
      window.location.host +
      "/#/phoneUpPic?token=" +
      this.$cookies.get("TOKEN");
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.Timer);
  },
  methods: {
    onConfirmIpPhone() {
      this.$request({
        url: "/api/userrole/user/confirm-phone-num",
        method: "POST",
        data: {
          phoneNumber: this.ipForm.Phone,
        },
      })
        .then((res) => {
          this.$store.commit("setIpPhone", this.ipForm.Phone);
          this.$store.commit("setIpLogin", false);
          this.$message.success("绑定成功");
        })
        .catch((e) => {
          this.$message.error("绑定失败");
        })
        .finally(() => {
          this.ipDialogVisible = false;
        });
    },
    close() {
      this.ipDialogVisible = false;
      this.$store.commit("setIpLogin", false);
    },
    // frequency自减一
    minusOne() {
      this.frequency -= 1;
    },
    deleteFiled(index) {
      this.addFieldObj.splice(index, 1);
    },
    updateForm(data) {
      this.addFieldObj.push({ ...data });
      this.addGenealogyForm.bookDto.extendFieldDtos = this.addFieldObj;
    },
    // 新增字段
    addField() {
      this.frequency += 1;
      this.filedForm.fieldEnglishName = "field" + this.frequency;
      this.isAddFieldDialogShow = true;
    },
    handleCurrentChange(val) {
      this.imgPageIndex = val;
      this.GetTempImages();
    },
    //上传函数
    submitUpLoad() {
      // console.log(this.fileList, "fileList");
      if (this.fileList.length === 0) {
        return this.$message.error("请添加上传图片");
      }
      if (this.fileList.length > this.limit) {
        return this.$message.error("请添加上传图片:" + this.limit);
      }
      const formData = new FormData();
      this.fileList.forEach((item, index) => {
        formData.append(`formFiles`, item.raw);
      });
      let loadingInstance = Loading.service({
        fullscreen: true,
      });

      this.$request({
        url: "/api/fileinfo/file/users-save-files/",
        method: "POST",
        headers: {
          "content-type": "multipart/form-data;boundary=${boundary}",
        },
        data: formData,
      })
        .then(async (res) => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
          });
          this.fileList.splice(0, this.fileList.length);
          res.map((item, index) => {
            item.pictureFileName = item.pictureFileName;
            item.picturePath = item.picturePath;
            this.addGenealogyForm.pictureInfoDtos.push(item);
          });
          await this.SavaImg(this.addGenealogyForm);
          await this.GetTempImages();
          //return this.$message.success("上传并送入识别成功");
        })
        .finally((e) => {
          this.loading = false;
        });
    },
    // 获取用户资料
    getLoginUserInfo() {
      this.$request({
        url: "/api/userrole/user/login-user",
        method: "GET",
      })
        .then((res) => {
          this.$store.commit("setUserInfo", res);
        })
        .catch((e) => {});
    },
    //送入识别
    distinguish() {
      this.loading = true;
      this.$request({
        url: "/api/discerntask/discern-task/create-task",
        method: "POST",
        data: {
          projectId: this.addGenealogyForm.bookDto.projectId,
          sourceID: this.addGenealogyForm.bookDto.sourceID,
          title: this.addGenealogyForm.bookDto.title,
          sourceIdentify: true,
          isVertical:
            this.addGenealogyForm.bookDto.layoutFormat == "1" ? false : true,
          forceUpdate: this.updateType == "1" ? false : true,
        },
      })
        .then((res) => {
          console.log(res, "res");
          this.loading = false;
          this.getLoginUserInfo();
          // window.location.href = "/#/identifyPross";
          this.$emit("identifyProssClick");
          // window.event.returnValue = false;
          return this.$message.success("已成功送入识别");
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    /* 保存图片到文献 */
    SavaImg(upImgArray, type) {
      this.$request({
        url: "/api/document/user-center/file-list",
        method: "POST",
        data: upImgArray.pictureInfoDtos,
      })
        .then((res) => {
          // this.getOriImageThumbnails();
          // this.addGenealogyForm = res;
          // this.distinguish();
          this.isUploadSuccess = true;
          upImgArray.pictureInfoDtos = [];
          this.GetTempImages(type);
          return this.$message.success("上传成功");
        })
        .catch((e) => {});
    },
    submitUpLoadNew() {
      this.loadingInstance = Loading.service({
        fullscreen: true,
        target: "#col-right",
      });
      this.imgUpload();
      this.zipUpload();
    },
    imgUpload() {
      if (this.imgArr.length > 0) {
        if (this.uploadThreadCount > 0) {
          this.uploadThreadCount -= 1;
          this.submitUpLoadThread();
        }
      }
      if (this.imgArr.length > 0) {
        setTimeout(() => {
          this.imgUpload();
        }, 1000);
      }
    },
    zipUpload() {
      if (this.zipArr.length > 0) {
        if (this.zipUploadThreadCount > 0) {
          this.zipUploadThreadCount -= 1;
          this.zipUpLoadThread();
        }
      }
      if (this.zipArr.length > 0) {
        setTimeout(() => {
          this.zipUpload();
        }, 1000);
      }
    },
    loadingClose() {
      if (!this.imgArr.length && !this.zipArr.length) {
        this.loadingInstance.close();
      }
    },
    //多线程上传
    submitUpLoadThread() {
      //读取并上传第一条
      if (this.imgArr.length > 0) {
        // let loadingInstance = Loading.service({
        //   fullscreen: true,
        //   target: "#col-right",
        // });

        var temLength =
          this.imgArr.length < this.uploadManyCount
            ? this.imgArr.length
            : this.uploadManyCount;
        var temList = this.imgArr.splice(0, temLength);
        this.fileList = this.zipArr.concat(this.imgArr);
        const formData = new FormData();
        temList.forEach((item, index) => {
          console.log(item, "item.raw");
          formData.append(`formFiles`, item.raw);
        });
        console.log(formData, "formdata");
        this.$request({
          url: "/api/fileinfo/file/users-save-files/",
          method: "POST",
          headers: {
            "content-type": "multipart/form-data;boundary=${boundary}",
          },
          timeout: 30000,
          data: formData,
        })
          .then((res) => {
            console.log(res, "resssssssss");
            // this.$nextTick(() => {
            //   // 以服务的方式调用的 Loading 需要异步关闭
            //   loadingInstance.close();
            // });
            var temAddGenealogyForm = { pictureInfoDtos: [] };
            res.map((item) => {
              item.pictureFileName = item.pictureFileName;
              item.picturePath = item.picturePath;
              temAddGenealogyForm.pictureInfoDtos.push(item);
            });
            this.SavaImg(temAddGenealogyForm, true);
          })
          .catch(() => {
            this.$message.error("上传失败！");
            this.loadingInstance.close();
          })
          .finally((e) => {
            this.loading = false;
            this.uploadThreadCount += 1;
          });
      }
    },
    zipUpLoadThread() {
      //读取并上传第一条
      if (this.zipArr.length > 0) {
        var temLength =
          this.zipArr.length < this.uploadManyCount
            ? this.zipArr.length
            : this.uploadManyCount;
        var temList = this.zipArr.splice(0, temLength);
        this.fileList = this.zipArr.concat(this.imgArr);

        const formData = new FormData();
        temList.forEach((item, index) => {
          console.log(item, "item.raw");
          formData.append(`formFiles`, item.raw);
        });
        console.log(formData, "formdata");
        this.$request({
          url: "/api/fileinfo/file/users-save-files-zpis",
          method: "POST",
          headers: {
            "content-type": "multipart/form-data;boundary=${boundary}",
          },
          timeout: 30000,
          data: formData,
        })
          .then((res) => {
            var temAddGenealogyForm = { pictureInfoDtos: [] };
            res.map((item, index) => {
              item.pictureFileName = item.pictureFileName;
              item.picturePath = item.picturePath;
              temAddGenealogyForm.pictureInfoDtos.push(item);
            });
            this.SavaImg(temAddGenealogyForm, true);
          })
          .catch(() => {
            this.$message.error("上传失败！");
            this.loadingInstance.close();
          })
          .finally((e) => {
            this.loading = false;
            this.zipUploadThreadCount += 1;
          });
      }
    },
    /* 保存图片到文献 */
    SavaImgToDocument() {
      let field = this.$refs.fieldForm.addGenealogyForm;
      let tempField = this.$refs.fieldForm.tempField;
      if (this.uploadImageList.length <= 0)
        return this.$message.info("请上传图片！");
      console.log(tempField, "tempField");
      const payload = {};
      const tempList = {};
      if (field.bookDto.keyword === "") {
        field.bookDto.keyword = "口";
      }
      this.addGenealogyForm = field;
      payload.documentType = field.bookDto.documentType;
      delete field.bookDto.documentType;
      switch (payload.documentType) {
        // 古籍
        case 134:
          payload.ancientDto = field.bookDto;
          tempField.forEach((v) => {
            tempList[v.label] = v[v.label];
          });
          Object.assign(payload.ancientDto, tempList);
          break;
        // 谱牒
        case 133:
          payload.genealogyDto = field.bookDto;
          tempField.forEach((v) => {
            tempList[v.label] = v[v.label];
          });
          Object.assign(payload.genealogyDto, tempList);
          break;
        // 方志
        case 131:
          payload.localRecordsDto = field.bookDto;
          tempField.forEach((v) => {
            tempList[v.label] = v[v.label];
          });
          Object.assign(payload.localRecordsDto, tempList);
          break;
        // 历史档案
        case 132:
          payload.historicalArchivesDto = field.bookDto;
          tempField.forEach((v) => {
            tempList[v.label] = v[v.label];
          });
          Object.assign(payload.historicalArchivesDto, tempList);
          break;
        // 图书
        case 100:
          payload.bookDto = field.bookDto;
          break;
        case 120:
          payload.bookDto = field.bookDto;
          break;
      }
      if (this.addGenealogyForm.bookDto.title === "") {
        this.addGenealogyForm.bookDto.title = "口";
      }

      // if (this.addGenealogyForm.bookDto.keyword === "") {
      //   this.addGenealogyForm.bookDto.keyword = "口";
      // }
      // this.addGenealogyForm.bookDto.documentTypeText =
      //   this.addGenealogyForm.bookDto.documentType;
      // if (typeof this.addGenealogyForm.bookDto.documentTypeText === "number") {
      //   this.$store.state.DocumentTypeOption.forEach((v) => {
      //     if (v.value === this.addGenealogyForm.bookDto.documentTypeText) {
      //       this.addGenealogyForm.bookDto.documentTypeText = v.label;
      //     }
      //   });
      // } else if (
      //   typeof this.addGenealogyForm.bookDto.documentTypeText === "string"
      // ) {
      //   this.addGenealogyForm.bookDto.documentType = 0;
      // }
      // console.log(this.addGenealogyForm, "addGenealogyForm");

      // 支付
      console.log(payload, "payload");
      this.onPayment(payload);
    },
    // 支付
    onPayment(payload) {
      this.$request({
        url: "/api/document/user-center/upload-book",
        method: "POST",
        data: JSON.stringify(payload),
      })
        .then((res) => {
          // 识别页数不够，充值
          if (res.statusCode === 409) {
            // 保存字段参数
            this.parameter = payload;
            this.payInfo = res.payOrderInfoDto;
            this.payDialog = true;
            this.paymentStatus();
            this.payTimer = setInterval(this.paymentStatus, 1000);
            // this.selectRecharge(res.payOrderInfoDto.remark)
          } else if (res.statusCode === 200) {
            this.addGenealogyForm = res;
            this.addGenealogyForm.bookDto = res.documentBookDto;
            // console.log(this.addGenealogyForm, 'addGenealogyForm')
            this.distinguish();
            // 调用子组件清空表单方法
            this.$refs.fieldForm.emptyForm();
            return this.$message.success("送入识别成功");
          }
          // this.getOriImageThumbnails();
        })
        .catch((e) => {
          console.log(e, "错误");
          // console.log(e.error.message)
          // this.selectRecharge(e.error.message)
        });
    },
    /* 获取已上传图片 */
    async GetTempImages(type) {
      await this.$request({
        url: "/api/document/user-center",
        method: "GET",
        params: {
          PageSize: this.imgPageSize,
          PageIndex: this.imgPageIndex,
        },
      })
        .then((res) => {
          this.imgTotals = res.totalCount;
          this.uploadImageList = res.items;
          // if(this.uploadImageList.length !== res.items.length) {
          //   this.uploadImageList = res.items;
          // }
          if (type) {
            this.loadingClose();
          }
        })
        .catch((e) => {});
    },
    onSuccess(response, file, fileList) {
      console.log("上传成功");
    },
    onError(err, file, fileList) {
      console.log("上传失败");
    },
    //移除
    handleRemove(index) {
      this.$confirm("确认删除？")
        .then(() => {
          this.fileList.splice(index, 1);
          if (index >= this.zipArr.length - 1) {
            this.imgArr.splice(index - this.zipArr.length - 1, 1);
          } else {
            this.zipArr.splice(index, 1);
          }
        })
        .catch(() => {});
    },
    handleImgRemove(id) {
      this.$confirm("确认删除？")
        .then(() => {
          this.$request({
            // url: "/api/document/picture/" + id,
            url: "/api/document/user-center/" + id,
            method: "DELETE",
          })
            .then(async (res) => {
              //this.getOriImageThumbnails();
              // this.ImageThumbnails.splice(imgIndex, 1);
              await this.GetTempImages();
              return this.$message.success("删除成功");
            })
            .catch((e) => {});
        })
        .catch(() => {});
    },
    //预览
    handlePreview(imageInfo, index, state) {
      // this.elImgRef = this.$refs[`el-img-${index}`]
      if (state === "notUploaded") {
        this.dialogStatus = false;
        this.previewDialogImageUrl = imageInfo;
      } else if (state === "upload") {
        this.dialogStatus = true;
        this.currentImageInfo = imageInfo;
        this.previewDialogImageUrl =
          `${this.baseurl}api/fileinfo/image/GetOriImage?filename=${imageInfo.pictureFullName}&ocrSource=1` +
          Date.now();
      }
      this.currentIndex = index;
      this.previewDialogVisible = true;
    },
    imgHandleClose() {
      this.imgDialogFormVisible = false;
    },
    //文件超出个数限制时的函数
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    async handleChange(file, fileList) {
      // var maxFileCount = this.limit;
      // if (this.limit > this.$store.getters.getUserInfo.identifyCount)
      //   maxFileCount = this.$store.getters.getUserInfo.identifyCount;
      // if (fileList.length > maxFileCount) {
      //   this.$message.warning(`当前限制选择${maxFileCount} 个文件`);
      // } else {
      //   if (file.raw.type == "image/tiff") {
      //     file.url = await this.uploadTiff(file);
      //   } else file.url = URL.createObjectURL(file.raw);
      //   this.fileList.push(file);
      // }

      if (file.raw.type == "image/tiff") {
        file.url = await this.uploadTiff(file);
        this.imgArr.push(file);
      } else if (file.name.endsWith(".zip")) {
        file.url = require("../assets/images/zip.png");
        file.type = "zip";
        this.zipArr.push(file);
      } else {
        file.url = URL.createObjectURL(file.raw);
        this.imgArr.push(file);
      }
      this.fileList = this.zipArr.concat(this.imgArr);
    },
    // 选择tiff图片上传本地转码方法封装
    async uploadTiff(file) {
      
      // const response = await fetch(URL.createObjectURL(file.raw));
      // const buffer = await response.arrayBuffer();
      // const Tiff = require("tiff.js");
      // const tiff = new Tiff({ buffer });
      // console.log(tiff.toDataURL());
      // return tiff.toDataURL();

      return URL.createObjectURL(file.raw);
    },
    init() {
      const image = this.$refs.cutImg;
      // this.cropper = new Cropper(image, {
      //     viewMode: 1, // 只能在裁剪的图片范围内移动
      //     dragMode: 'move', // 画布和图片都可以移动
      //     aspectRatio: 0, // 裁剪区默认正方形 0: 不是正方形 1：正方形
      //     autoCropArea: 1, // 自动调整裁剪图片
      //     cropBoxMovable: false, // 禁止裁剪区移动
      //     cropBoxResizable: true, // 裁剪区缩放
      //     background: false, // 关闭默认背景
      //     rotatable: true // 图片旋转
      // })
      this.cropper = new Cropper(image, {
        dragMode: "move",
        autoCropArea: 0.9,
        restore: false,
        guides: false,
        highlight: false,
        cropBoxMovable: true,
        cropBoxResizable: true,
        toggleDragModeOnDblclick: false,
        rotatable: true,
      });
    },
    // 裁剪
    // onCut() {
    //   if(!this.operation) {
    //     this.init()
    //   }
    //   this.operation = 3
    // }

    // 确认
    onConfirm() {
      if (this.operation) {
        // const cutInfo = this.cropper.getData({
        //         imageSmoothingQuality: 'high'
        //       })
        // this.loadUpdatePhoto(cutInfo)
        // // 关闭裁剪区
        // this.cropper.destroy()
        // this.operation = null
        return;
      } else {
        this.previewDialogVisible = false;
      }
      this.rotateRequest(this.requestDegrees);
      this.dialogStatus = false;
      // 解决浏览器缓存
      this.timestamp = Date.now();
      // this.$nextTick(() => {
      //   console.log(this.elImgRef[0].src)
      //   this.elImgRef[0].src = this.elImgRef[0].src + Date.now() + 123
      //   console.log(this.elImgRef[0].src)
      // })
    },
    // 裁剪图片上传
    async loadUpdatePhoto(cutInfo) {
      const res = await this.$request({
        method: "GET",
        url: "/api/fileinfo/image/CutPic",
        params: {
          imagePath: this.currentImageInfo.pictureFullName,
          x: cutInfo.x,
          y: cutInfo.y,
          w: cutInfo.height,
          h: cutInfo.width,
        },
      });
      this.$refs.cutImg.src = this.previewDialogImageUrl + Math.random();
    },

    //#reginon 旋转节流
    // // 旋转,节流处理
    // onRotate: _.throttle(function (degrees) {
    //   this.$request({
    //     method: 'GET',
    //     url: '/api/fileinfo/image/RotateImg',
    //     params: {
    //       imagePath: this.currentImageInfo.pictureFullName,
    //       angle: degrees
    //     }
    //   }).then(()  => {
    //     // 添加随机数，解决浏览器缓存问题,导致的图片不实时加载
    //     //   this.$refs.cutImg.src = this.previewDialogImageUrl + Math.random()
    //     this.timestamp = Date.now()
    //   }).catch(err => {
    //     console.log(err, 'err')
    //   })
    //
    //   if(this.$refs.cutImg.style.transform) {
    //     const angle = this.proportionalScaling(degrees)
    //     this.$refs.cutImg.style = `transform: rotate(${angle}deg);`
    //
    //   } else {
    //     const originalWidth = this.$refs.cutImg.width
    //     const originalHeight = this.$refs.cutImg.height
    //     this.proportionalScaling(degrees, true, originalWidth, originalHeight)
    //     this.$refs.cutImg.style = `transform: rotate(${degrees}deg)`
    //   }
    // },1000),
    // #endregion

    //旋转
    onRotate(degrees) {
      this.requestDegrees += Number(degrees);
      if (this.requestDegrees === 360 || this.requestDegrees === -360)
        this.requestDegrees = 0;
      if (this.$refs.cutImg.style.transform) {
        const angle = this.proportionalScaling(degrees);
        this.$refs.cutImg.style = `transform: rotate(${angle}deg);`;
      } else {
        const originalWidth = this.$refs.cutImg.width;
        const originalHeight = this.$refs.cutImg.height;
        this.proportionalScaling(degrees, true, originalWidth, originalHeight);
        this.$refs.cutImg.style = `transform: rotate(${degrees}deg)`;
      }
    },
    // 旋转网络请求
    rotateRequest(requestDegrees) {
      this.$request({
        method: "GET",
        url: "/api/fileinfo/image/RotateImg",
        params: {
          imagePath: this.currentImageInfo.pictureFullName,
          angle: requestDegrees,
        },
      })
        .then(() => {
          // 添加随机数，解决浏览器缓存问题,导致的图片不实时加载
          // this.$refs.cutImg.src = this.previewDialogImageUrl + Math.random()
          this.timestamp = Date.now();
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
    proportionalScaling(degrees, flag = false, originalWidth, originalHeight) {
      const regEx = /[^\d|^\.|^\-]/g; // 提取现有的旋转角度
      let angle =
        Number(this.$refs.cutImg.style.transform.replace(regEx, "")) +
        parseInt(degrees);
      if (flag) {
        angle = parseInt(degrees);
        this.rotateImgWidth = originalWidth;
        this.rotateImgHeight = originalHeight;
        this.imgBoxWidth = this.$refs.imgBox.style.width;
      }
      // 动态实现等比缩放
      if (angle === 360 || angle === -360) {
        // 旋转一周 = 没旋转
        angle = 0;
      } else if (
        angle === 90 ||
        angle === -90 ||
        angle === 270 ||
        angle === -270
      ) {
        // 找出长边和短边
        // let max = Math.max(originalWidth,originalHeight)
        // let min = Math.min(originalWidth,originalHeight)
        // 计算比例
        // if(max >= 500) {
        //   const proportion = (max - 500) / max || 1 // 如果max = 500 ，比例设置为1
        //   max  =  500
        //   min = min * proportion
        // } else {
        //   const proportion = (500 - max) / max
        //   max = 500
        //   min = min * proportion
        // }
        if (this.rotateImgWidth >= this.rotateImgHeight) {
          console.log("宽大于高");
          // 数据驱动视图更新是异步的
          this.$nextTick(() => {
            this.$refs.cutImg.style.width = "500px";
          });
        } else {
          console.log("高大于宽");
          this.$nextTick(() => {
            this.$refs.cutImg.style.maxHeight = "none";
            this.$refs.cutImg.style.height = "1014px";
          });
        }
      } else if (angle === 0 || angle === 180 || angle === -180) {
        this.$nextTick(() => {
          if (this.rotateImgWidth >= this.rotateImgHeight) {
            this.$refs.cutImg.style.height = this.rotateImgHeight + "px";
          } else {
            this.$refs.cutImg.style.width = this.rotateImgWidth + "px";
          }
        });
      }
      return angle;
    },
    // 取消
    onCancel() {
      if (this.operation) {
        // 关闭裁剪框
        this.cropper.destroy();
        this.operation = null;
        return;
      } else {
        this.previewDialogVisible = false;
      }
      this.operation = null;
      this.dialogStatus = false;
    },
    // 充值选择
    selectRecharge(message) {
      this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
      })
        .then(() => {
          // this.$message({
          //   type: 'success',
          // });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消充值",
          });
        });
    },
    // 查询支付状态
    paymentStatus() {
      this.$request({
        method: "GET",
        url: "/api/document/user-center/order-by-nO",
        params: {
          orderNo: this.payInfo.orderNo,
        },
      })
        .then(async (res) => {
          // 支付完成
          // const num = Math.floor(Math.random() * 10) + 1
          // console.log(num, '随机数')
          // console.log(res.tradeState)
          if (res.tradeState === 0) {
            await this.getLoginUserInfo();
            clearInterval(this.payTimer);
            // this.$message.success({message: '支付成功,请送入识别！'})
            this.payDialog = false;
            return this.$confirm("支付成功,请送入识别！", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              closeOnClickModal: false,
              type: "success",
            })
              .then(async () => {
                await this.onPayment(this.parameter);
              })
              .catch(() => {});
          }
        })
        .catch((err) => {
          console.log(err, "失败");
        });
    },
    // 支付弹框关闭
    onPayDialogClose() {
      clearInterval(this.payTimer);
    },
  },
};
</script>

<style lang="less" scoped>
.pay-dialog {
  .pay-dialog-main {
    display: flex;
    //justify-content: space-between;
    //align-items: center;

    .pay-code {
      flex: 2;
    }
    .pay-remark {
      flex: 1;
      margin-top: 20px;
      margin-left: 10px;
      width: 150px;
      line-height: 30px;
      font-size: 16px;
      color: #000;

      .money {
        color: red;
        font-size: 18px;
        font-style: normal;
      }
    }
  }
}

/deep/ .el-container .is-vertical {
  /deep/ .el-main {
    height: 100%;
  }
}

/deep/ .el-col .el-col-12 {
  //width: 100%;
  //background: red;
}

/deep/ .el-dialog__header {
  background: #409eff;
}

/deep/ .el-dialog__body {
  padding-top: 10px;
}

/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #000;
}

.dialog {
  .operation {
    margin: 5px;
  }
  .imgBox {
    height: 500px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .imgBox-img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      //object-fit: cover;
    }
  }
}

.el-row {
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.fileimglist {
  /deep/ .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.file-image {
  position: relative;
  box-sizing: border-box;
  display: flex;
  padding: 10px;
  margin: auto;
  // background-color: #f2f2f2;
  text-align: left;
  width: 100%;
  height: 620px;
  overflow: auto;

  /deep/ .test-class {
    padding-right: 10px;
    border-right: 1px solid #ccc;

    .item {
      // display: flex;
      // justify-content: space-between;

      .el-upload__tip {
        padding-top: 10px;
      }

      .el-upload-dragger {
        width: 310px;
      }

      .upload-code {
        display: flex;
        justify-content: space-around;
      }

      .image {
        width: 310px;
        height: 180px;
        // background: red;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px dashed #409eff;
        border-radius: 6px;
      }

      .image:hover {
        border: 1px dashed #f64343;
      }
    }

    .el-upload-dragger {
      border: 1px dashed #409eff;
    }

    .el-upload-dragger:hover {
      border: 1px dashed #f64343;
    }
  }

  /deep/ .form {
    width: 700px;

    .addField {
      margin-left: 10px;
      .add-icon {
        color: #1e9fff;
        margin-top: 15px;
        cursor: pointer;
      }
    }

    .filedspan {
      padding: 0 1px;
      display: none;
      position: absolute;
      top: 0;
      right: -15px;
      z-index: 99999999;
      color: red;
      cursor: pointer;
      margin-top: 13px;
      span {
        display: block;
        line-height: 20px;
      }
    }
    .filedspan:hover {
      display: block;
    }

    .el-form {
      .el-form-item__label {
        color: #409eff;
      }

      .el-input__inner {
        border: 1px solid #409eff;
      }

      .el-input__inner:focus {
        border: 1px solid #f64343;
      }

      .el-textarea__inner {
        border: 1px solid #409eff;
      }

      .el-textarea__inner:focus {
        border: 1px solid #f64343;
      }

      .el-input--suffix .el-input__inner {
        padding-right: 15px;
      }
    }
  }

  .not-uploaded {
    max-height: 320px;
    overflow-y: auto;
  }

  .u-list {
    box-sizing: border-box;
    //display: inline-block;
    //padding-left: 30px;
    //margin-left: 30px;
    //width: 600px;
    //justify-content: space-evenly;
    //display: flex;
    ////display: inline-block !important;
    //flex-wrap: wrap;
    //overflow: auto;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;

    // 解决 flex 布局最后一排对不齐
    //&::after{
    //  content: '';
    //  //border: 1px solid #000;
    //  //flex: 1;
    //  width: 120px;
    //}

    .notUploaded {
      position: relative;
      .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: -2px;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.3);
      }
    }

    .u-li {
      position: relative;
      display: inline-block !important;
      margin: 10px 20px 10px 10px;
      width: 120px;
      height: 140px;
      border-radius: 4px;

      .el-image {
        width: 100%;
        height: 120px;
        border-radius: 4px;
        //border: 1px solid #ccc;
      }

      /deep/ .el-upload--picture-card {
        width: 100%;
        height: 100%;
      }

      .upload-list-icon {
        display: none;
        box-sizing: border-box;
        padding: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        color: #fff;
        font-size: 20px;
        text-align: center;

        span {
          margin: 0 15px;
          cursor: pointer;
        }
      }
    }

    .u-li:hover .upload-list-icon {
      display: block;
    }

    .u-li:nth-of-type(3n + 2) {
      //margin: 10px 20px;
      //margin: 10px
    }
    .u-li:last-child {
      margin-right: auto;
    }
  }

  .col-left {
    .pagination-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 10px;
    }
  }
}

.preview {
  /deep/ .el-dialog {
    margin-top: 15px !important;
    height: 800px;
    overflow: hidden;
  }

  .previewimg {
    width: 100%;
    height: 700px;

    .el-image {
      width: 100%;
      height: 100%;
    }
  }
}

.account {
  padding: 20px;
  box-sizing: border-box;
  background-color: rgb(242, 242, 242);
}

.box-card {
  width: 100%;
}

.filedspan {
  padding: 0 1px;
  display: none;
  position: absolute;
  top: 0;
  right: -15px;
  z-index: 99999999;
  color: red;
  cursor: pointer;
}

.el-input:hover + .filedspan {
  display: block !important;
}

.filedspan:hover {
  display: block;
}

/deep/ .el-dialog {
  width: 1054px;
}

/deep/ .el-form-item {
  .el-table th {
    padding: 0;
  }

  margin-bottom: 12px;
}

@media screen and(max-width: 1280px) {
  /deep/ .el-card__body {
    padding: 12px !important;
  }

  /deep/ .el-button {
    padding: 10px 10px !important;
  }

  /deep/ .el-input {
    padding: 0px !important;
  }
}
// dialog 剧中显示
/deep/ .el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  > .el-dialog__body {
    overflow: auto;
    color: #666666;
  }
}
</style>
