<template>
  <el-dialog title="新增字段" :visible.sync="isShow" :close-on-click-modal="false" width="40%">
    <el-form
        :model="filedForm"
        ref="filedForm"
        :rules="filedFormRole"
        label-width="120px"
    >
      <el-form-item label="扩展字段中文名" prop="fieldChinaName" class="add-field-item">
        <el-input
            v-model="filedForm.fieldChinaName"
            placeholder="扩展字段中文名"
        ></el-input>
      </el-form-item>
      <el-form-item label="扩展字段英文名" prop="fieldEnglishName" class="add-field-item">
        <el-input
            v-model="filedForm.fieldEnglishName"
            placeholder="扩展字段英文名"
        ></el-input>
      </el-form-item>
      <el-form-item label="扩展字段值" prop="fieldValue" class="add-field-item">
        <el-input
            v-model="filedForm.fieldValue"
            placeholder="扩展字段内容信息填写"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onConfirm">添 加</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "AddField",
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    filedForm: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      filedFormRole: {
        fieldEnglishName: [
          {
            required: true,
            message: "请输入扩展字段英文名",
            trigger: "blur",
          }
        ],
        fieldChinaName: [
          {
            required: true,
            message: "请输入扩展字段中文名",
            trigger: "blur",
          },
        ],
        fieldValue: [
          {
            required: true,
            message: "请输入扩展字段值",
            trigger: "blur",
          },
        ]
      }
    }
  },
  methods: {
    onConfirm() {
      this.$refs.filedForm.validate(async valid => {
        if(valid) {
            await this.$emit('updateForm', this.filedForm)
            this.$emit('update:is-show', false)
            this.$refs.filedForm.resetFields()
        }
      })
    },
    onCancel() {
      this.$emit('update:is-show', false)
      this.$emit('minusOne')
      this.$refs.filedForm.resetFields()
    }
  }
}
</script>

<style scoped lang="less">
.add-field-item {
  margin-top: 20px;
}
/deep/ .el-dialog__headerbtn {
  display: none;
}
</style>
