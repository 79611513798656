import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ipLogin: JSON.parse(localStorage.getItem('ipLogin')) || false,
    ipPhone: JSON.parse(localStorage.getItem('ipPhone')) || '',
    token: null,
    userInfo: {},
    // 文献类型 100 图书,110 期刊,120 报纸,131 方志、132 历史档案、133 谱牒、134 古籍，150 音频，160 视频
    DocumentTypeOption: [{
      value: 100,
      label: "图书",
    }, {
      value: 110,
      label: "期刊",
    }, {
      value: 120,
      label: "报纸",
    }, {
      value: 131,
      label: "方志",
    }, {
      value: 132,
      label: "历史档案",
    }, {
      value: 133,
      label: "谱牒",
    }, {
      value: 134,
      label: "古籍",
    }, {
      value: 150,
      label: "音频",
    }, {
      value: 160,
      label: "视频",
    }],
    // 语种
    language: [],
    // 订单状态字典
    dictionaries: [
      {
        value: 0,
        label: '支付成功'
      },
      {
        value: 1,
        label: '转入退款'
      },
      {
        value: 2,
        label: '未支付'
      },
      {
        value: 3,
        label: '已关闭'
      },
      {
        value: 4,
        label: '已撤销'
      },
      {
        value: 5,
        label: '用户支付中'
      },
      {
        value: 6,
        label: '支付失败'
      },
    ],
    // 古籍
    ancientWorks: [
      {
        val: '尺寸',
        label: 'measurement',
        measurement: ''
      },
      {
        val: '丛编项',
        label: 'seriesArea',
        seriesArea: ''
      }
    ],
    // 谱牒
    genealogy: [
      {
        val: '姓氏',
        label: 'surname',
        surname: ''
      },
      {
        val: '始祖',
        label: 'ancestor',
        ancestor: ''
      },
      {
        val: '散居地',
        label: 'diaspora',
        diaspora: ''
      },
      {
        val: '堂号',
        label: 'hall',
        hall: ''
      }
    ],
    // 方志
    localRecords: [
      {
        val: '丛编项',
        label: 'seriesArea',
        seriesArea: ''
      },
      {
        val: '装订方式',
        label: 'bookBinding',
        bookBinding: ''
      },
      {
        val: '升本',
        label: 'bookSize',
        bookSize: ''
      },
      {
        val: '印数',
        label: 'printNumber',
        printNumber: ''
      },
      {
        val: '字数',
        label: 'wordNumber',
        wordNumber: ''
      },
      {
        val: '定价',
        label: 'price',
        price: ''
      },
      {
        val: '数字加工来源格式',
        label: 'digitialSource',
        digitialSource: ''
      },
      {
        val: '数字化加工机构',
        label: 'digitialUnit',
        digitialUnit: ''
      },
      {
        val: '数字化加工交付时间',
        label: 'idadDate',
        idadDate: ''
      },
      {
        val: '数字化加工执行标准',
        label: 'standardOfExecution',
        standardOfExecution: ''
      },
      {
        val: '来源方式',
        label: 'sourceType',
        sourceType: ''
      },
      {
        val: '提供方本地资源',
        label: 'localID',
        localID: ''
      },
      {
        val: '提供方名称',
        label: 'sourceFrom',
        sourceFrom: ''
      },
      {
        val: '提供方有无marc记录',
        label: 'hasMarc',
        hasMarc: ''
      },
      {
        val: '收藏单位代码',
        label: 'collectorCode',
        collectorCode: ''
      },
      {
        val: '收藏单位名称',
        label: 'collectorName',
        collectorName: ''
      },
      {
        val: '典藏号',
        label: 'repositoryNumber',
        repositoryNumber: ''
      },
      {
        val: '排架区分号',
        label: 'shefCode',
        shefCode: ''
      },
      {
        val: '入藏日期',
        label: 'collectDate',
        collectDate: ''
      },
      {
        val: '完残程度',
        label: 'integrityLevel',
        integrityLevel: ''
      },
      {
        val: '收藏人',
        label: 'storer',
        storer: ''
      },
      {
        val: '收藏历史',
        label: 'storeStory',
        storeStory: ''
      },
      {
        val: '整理研究人',
        label: 'researcher',
        researcher: ''
      },
      {
        val: '整理研究历史',
        label: 'researchStory',
        researchStory: ''
      }
    ],
    // 历史档案
    historicalArchives: [

      {
        val: '档案类型',
        label: 'fileType',
        fileType: ''
      },
      {
        val: '档案编号',
        label: 'fileNumber',
        fileNumber: ''
      },
      {
        val: '信息来源',
        label: 'source',
        source: ''
      },
    ],
    //组织机构
    OrganistationList: []
  },
  mutations: {
    //token
    setToken(state, option) {
      state.token = option
    },
    //userInfo
    setUserInfo(state, option) {
      state.userInfo = option
    },
    //语种类型
    setLanguage(state, option) {
      state.language = option
    },
    /* 组织机构 */
    GetOrganistationList(state, option) {
      state.OrganistationList = option
    },
    setIpLogin(state, payload) {
      state.ipLogin = payload
      localStorage.setItem('ipLogin', state.ipLogin)
    },
    setIpPhone(state, phone) {
      state.ipPhone = phone
      localStorage.setItem('ipPhone', state.ipPhone)
    }
  },
  getters: {
    getUserInfo(state) {
      console.log("getUserInfo:" + state.userInfo);
      return state.userInfo;
    },
    //语种类型
    getLanguage(state) {
      return state.language;
    },
    //文献类型
    getDocumentTypeOption(state) {
      console.log(state.DocumentTypeOption, 'state.DocumentTypeOption')
      // 过滤掉 报纸，音频，视频，期刊,并进行排序
      // && v.value !== 120 测试环境去掉
      return state.DocumentTypeOption.filter(v => v.value !== 150 && v.value !== 160 && v.value !== 110).sort((a, b) => b.value - a.value)
    },
  }
})
