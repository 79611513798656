<template>
  <div
    class="loginpages"
    v-loading="loading"
    element-loading-text="正在登录..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- 标题 -->
    <div
      class="wrap"
      :style="{ transform: 'scale(' + windowWidth / 1920 + ')' }"
    >
      <h3>
        <span>两岸数字文献AI识别加工平台</span>
      </h3>
      <div class="lgcontent">
        <!-- 登录/注册 -->
        <div class="logres">
          <div
            class="login"
            @click="currenClick(1)"
            :class="{ activelr: curren == 1 }"
          >
            登录
          </div>
          <div
            class="register"
            @click="currenClick(3)"
            :class="{ activelr: curren >= 2 }"
          >
            注册
          </div>
        </div>
        <!-- 账户密码登录表单 -->
        <div class="logincontent" v-show="curren == 1">
          <el-form
            :model="loginForm"
            :rules="rules"
            ref="loginForm"
            class="loginContainer"
          >
            <el-form-item class="username" prop="userName">
              <el-input
                v-model="loginForm.userName"
                placeholder="请输入用户名或手机号"
                clearable
                prefix-icon="el-icon-user-solid"
              ></el-input>
            </el-form-item>
            <el-form-item class="password" prop="password">
              <el-input
                v-model="loginForm.password"
                placeholder="请输入密码"
                prefix-icon="el-icon-user-solid"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item class="vcode" prop="vCode">
              <div style="display: flex">
                <el-input
                  v-model="loginForm.vCode"
                  placeholder="请输入验证码"
                  clearable
                  prefix-icon="el-icon-user-solid"
                  @keyup.enter.native="loginSubmit"
                ></el-input>
                <div style="width: 70%; height: 40px">
                  <el-image :src="imgsrc" @click.self="GetVcode"></el-image>
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="userName">
              <div class="loginsubmit" @click="loginSubmit">立即登录</div>
              <div class="IPlogin" @click="onIPLogin">IP登录</div>
            </el-form-item>
          </el-form>
        </div>
        <!-- 注册表单 用户名注册 暂时不用 -->
        <div v-show="curren == 2">
          <el-form
            :model="registerForm"
            :rules="registerRules"
            ref="registerForm"
            class="registercontent"
          >
            <el-form-item class="username" prop="username">
              <el-input
                v-model="registerForm.telephone"
                placeholder="请输入用户名"
                clearable
                prefix-icon="el-icon-user-solid"
              ></el-input>
            </el-form-item>
            <el-form-item class="password" prop="password">
              <el-input
                v-model="registerForm.password"
                placeholder="请输入密码"
                prefix-icon="el-icon-user-solid"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item class="password" prop="confirmPassword">
              <el-input
                v-model="registerForm.confirmPassword"
                placeholder="请确认密码"
                prefix-icon="el-icon-user-solid"
                show-password
              ></el-input>
            </el-form-item>
            <!-- 图片验证码 -->
            <el-form-item class="vcode" prop="vCode">
              <div style="display: flex">
                <el-input
                  v-model="registerForm.vCode"
                  placeholder="请输入图片验证码"
                  clearable
                  prefix-icon="el-icon-user-solid"
                ></el-input>
                <div style="width: 70%; height: 40px">
                  <el-image
                    :src="imgRegistersrc"
                    @click.self="GetRegisterVcode"
                  ></el-image>
                </div>
              </div>
            </el-form-item>

            <!-- 手机验证码 Todo: -->
            <!-- <el-form-item class="username" prop="pCode">
                            <div style="display: flex; align-items: center">
                                <el-input v-model="registerForm.pCode" placeholder="请输入手机验证码" clearable
                                    prefix-icon="el-icon-user-solid" style="width: 100%">
                                    <template #suffix>
                                        <div class="getvcode" v-if="isGetVcode" style="background: #999">
                                            {{ time }}s后重试
                                        </div>
                                        <div class="getvcode" @click="GetPhoneVcode" v-else>
                                            {{ again ? "获取验证码" : "重新获取" }}
                                        </div>
                                    </template>
                                </el-input>
                            </div>
                        </el-form-item>-->

            <!-- 注册按钮 -->
            <el-form-item>
              <div class="loginsubmit" @click="registerSubmit">立即注册</div>
            </el-form-item>
          </el-form>
          <div class="phonelogin" @click="currenClick(3)">切换手机号登录</div>
        </div>
        <!-- 手机验证码登录注册表单 -->
        <div v-show="curren == 3">
          <el-form
            :model="phoneloginForm"
            :rules="phoneRules"
            ref="phoneloginForm"
            class="registercontent"
          >
            <el-form-item class="username" prop="telephone">
              <el-input
                v-model.number="phoneloginForm.telephone"
                placeholder="请输入手机号"
                clearable
                prefix-icon="el-icon-user-solid"
              ></el-input>
            </el-form-item>
            <!-- 图片验证码 -->
            <el-form-item class="vcode" prop="vCode">
              <div style="display: flex">
                <el-input
                  v-model="phoneloginForm.PicVCode"
                  placeholder="请输入图片验证码"
                  clearable
                  prefix-icon="el-icon-user-solid"
                  auto-complete="false"
                ></el-input>
                <div style="width: 70%; height: 40px">
                  <el-image
                    :src="imgPhonesrc"
                    @click.self="GetRegisterPhoneVcode"
                  ></el-image>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="vcode" prop="vCode">
              <div style="display: flex; align-items: center">
                <el-input
                  v-model="phoneloginForm.vCode"
                  complete="off"
                  placeholder="请输手机短信验证码"
                  clearable
                  prefix-icon="el-icon-user-solid"
                ></el-input>
                <div
                  class="getvcode"
                  v-if="isGetVcode"
                  style="background: #999"
                >
                  {{ time }}s后重试
                </div>
                <div class="getvcode" @click="GetPhoneVcode" v-else>
                  {{ again ? "获取验证码" : "重新获取" }}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="password" prop="password">
              <el-input
                v-model="phoneloginForm.password"
                placeholder="请输入密码"
                prefix-icon="el-icon-user-solid"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item class="password" prop="confirmPassword">
              <el-input
                v-model="phoneloginForm.confirmPassword"
                placeholder="请确认密码"
                prefix-icon="el-icon-user-solid"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item prop="userName">
              <div class="loginsubmit" @click="registerPhoneSubmit">
                立即注册
              </div>
            </el-form-item>
          </el-form>
          <!-- <div class="phonelogin" @click="currenClick(2)">
                        切换普通注册
                    </div>-->
        </div>
        <!-- <div class="agreement">
                    <p>
                        点击登录即代表您同意
                        <span>《XXX隐私政策》</span>和<span
                            >《XXX服务协议》</span
                        >
                    </p>
                </div> -->
      </div>
      <div class="footer">
        <p>福建两岸信息技术有限公司</p>
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >闽ICP备17021731号-9</a
        >
        <p>Copyright © 2021-2022 LOGO 中文 English</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      loading: false,
      windowWidth: "",
      curren: 1, //1登录 2 注册
      imgsrc: "", //验证码
      imgRegistersrc: "", //验证码
      imgPhonesrc: "", //验证码
      isGetVcode: false, //是否显示倒计时
      time: 60, //倒计时
      again: true,
      /* 账户密码登录表单 */
      loginForm: {
        userName: "",
        password: "",
        vCode: "",
        isHasCode: true,
      },
      rules: {
        userName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        vCode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      /* 手机验证码登录表单 */
      phoneloginForm: {
        telephone: "",
        password: "",
        confirmPassword: "",
        vCode: "",
        PicVCode: "", //图形验证码
      },
      /* 注册表单 */
      registerForm: {
        telephone: "",
        password: "",
        confirmPassword: "",
        vCode: "",
        // 手机验证码
        pCode: "",
      },
      registerRules: {
        telephone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1[3|5|7|8|9]\d{9}$/,
            message: "请输入正确的号码格式",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
        vCode: [
          {
            required: true,
            message: "请输入图形验证码",
            trigger: "blur",
          },
        ],
        pCode: [
          {
            required: true,
            message: "请输入手机验证码！！",
            trigger: "blur",
          },
        ],
      },
      phoneRules: {
        telephone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1[3|5|7|8|9]\d{9}$/,
            message: "请输入正确的号码格式",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
        PicVCode: [
          {
            required: true,
            message: "请输入图形验证码",
            trigger: "blur",
          },
        ],
        pCode: [
          {
            required: true,
            message: "请输入手机验证码！！",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  beforeDestroy() {},
  created() {
    this.windowWidth = window.innerWidth;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    this.GetVcode();
  },
  mounted() {},

  methods: {
    // ip登录
    onIPLogin() {
      let data = {};
      if (this.$store.state.ipPhone) {
        data = { phoneNumber: this.$store.state.ipPhone.toString() };
      }
      this.$request({
        url: "/api/userrole/user/i-plogin",
        method: "POST",
        data,
      })
        .then((res) => {
          this.$cookies.set("TOKEN", res.token);
          this.$store.commit("setToken", res.token);
          this.$store.commit("setIpLogin", true);
          this.$router.replace({
            path: this.$route.query.redirect || "/home",
          });
          this.$message.success("登录成功");
        })
        .catch((e) => {
          this.$message.error("登录失败");
        })
        .finally(() => {
          this.$refs.ipForm.resetFields();
        });
    },
    // 图片验证码
    GetVcode() {
      this.imgsrc = "/api/app/user/vcode?random=" + Math.random();
    },
    GetRegisterVcode() {
      this.imgRegistersrc =
        "/api/app/user/vcode?optype=register&&random=" + Math.random();
    },
    GetRegisterPhoneVcode() {
      this.imgPhonesrc =
        "/api/app/user/vcode?optype=phoneCode&&random=" + Math.random();
    },
    //获取手机验证码
    GetPhoneVcode() {
      if (this.phoneloginForm.telephone === "") {
        return this.$message.warning("请先输入手机号码");
      }
      if (this.phoneloginForm.PicVCode === "") {
        return this.$message.warning("请先输入图形验证码");
      }
      this.again = false;
      this.isGetVcode = true;
      if (this.isGetVcode) {
        this.$request({
          method: "POST",
          url: "/api/userrole/telephone-code/send-msg",
          data: {
            vCode: this.phoneloginForm.PicVCode,
            telephone: this.phoneloginForm.telephone + "",
          },
        })
          .then((res) => {
            console.log(res, "注册");
          })
          .catch((err) => {
            console.log(err, "err");
          });
        var timer = setInterval(() => {
          this.time--;
          if (this.time <= 0) {
            clearInterval(timer);
            //this.time = 6;
            this.isGetVcode = false;
          }
        }, 1000);
      }
    },

    currenClick(index) {
      this.curren = index;
      if (this.curren == 1) {
        this.GetVcode();
      } else if (this.curren == 2) {
        this.GetRegisterVcode();
      } else if (this.curren == 3) {
        this.GetRegisterPhoneVcode();
      }
    },
    loginSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$request({
            url: "/api/userrole/user/login",
            method: "POST",
            data: this.loginForm,
          })
            .then((res) => {
              this.loading = false;
              this.$cookies.set("USER", res);
              this.$cookies.set("TOKEN", res.token);
              this.$store.commit("setToken", res.token);
              this.$router.replace({
                path: this.$route.query.redirect || "/home",
              });
            })
            .finally((e) => {
              this.GetVcode();
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    registerSubmit() {
      this.$refs.registerForm.validate((valid) => {
        console.log(valid, "valid");
        if (valid) {
          if (this.registerForm.password != this.registerForm.confirmPassword)
            return this.$message.warning("输入密码不一致");
          this.loading = true;
          this.$request({
            url: "/api/userrole/user/register",
            method: "POST",
            data: {
              ...this.registerForm,
              userName: this.registerForm.telephone,
            },
          })
            .then((res) => {
              for (let key in this.registerForm) {
                this.registerForm[key] = "";
              }
              this.curren = 1;
              return this.$message.success("注册成功");
            })
            .finally((e) => {
              this.loading = false;
              this.GetVcode();
            });
        } else {
          return false;
        }
      });
    },
    registerPhoneSubmit() {
      this.$refs.phoneloginForm.validate((valid) => {
        console.log(valid, "valid");
        if (valid) {
          if (
            this.phoneloginForm.password != this.phoneloginForm.confirmPassword
          )
            return this.$message.warning("输入密码不一致");
          this.loading = true;
          this.$request({
            url: "/api/userrole/user/phone-register",
            method: "POST",
            data: {
              ...this.phoneloginForm,
              organisationCode: this.$route.query?.organisationCode,
              telephone: this.phoneloginForm.telephone + "",
            },
          })
            .then((res) => {
              for (let key in this.phoneloginForm) {
                this.registerForm[key] = "";
              }
              this.curren = 1;
              return this.$message.success("注册成功");
            })
            .finally((e) => {
              this.loading = false;
              this.GetVcode();
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.IPlogin {
  position: absolute;
  right: -70px;
  bottom: -50px;
  width: 100px;
  height: 28px;
  border: 1px solid #4e6ef2;
  border-radius: 5px;
  font-family: MicrosoftYaHei;
  font-size: 20px;
  font-weight: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #fff;
  background: #4e6ef2;
  cursor: pointer;
  text-align: center;
}
.loginpages {
  box-sizing: border-box;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: url(~@/assets/image/vztion/bg1.png) center center no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  overflow: hidden;
}

h3 {
  text-align: center;
  padding-bottom: 15px;
}

h3 > span {
  font-size: 64px;
}

h3 > span:nth-of-type(1) {
  color: #6078cb;
}

h3 > span:nth-of-type(2) {
  color: #d63325;
}

h3 > span:nth-of-type(3) {
  color: #f2b505;
}

h3 > span:nth-of-type(4) {
  color: #4e6ef2;
}

.lgcontent {
  position: relative;
  padding: 60px 0;
  height: 500px;
  width: 680px;
  border-radius: 12px;
  background: #f7f9ff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
}

.lgcontent > .logres {
  margin: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login,
.register {
  padding: 0 10px;
  font-size: 28px;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  letter-spacing: 0px;
  color: #979797;
  vertical-align: middle;
  cursor: pointer;
}

.activelr {
  position: relative;
  font-size: 36px;
  color: #4e6ef2;
}

.activelr::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 4px;
  border-radius: 4px;
  background-color: #4e6ef2;
}

.logincontent {
  margin: 70px auto 0;
}

.loginContainer {
  margin: auto;
  width: 460px;
  position: relative;
}

.loginContainer > .username,
.loginContainer > .password {
  margin: 0 auto 48px;
  width: 460px;
  background: #ffffff;
  box-sizing: border-box;
}

.registercontent {
  margin: 60px auto 0;
}

.registercontent > .username,
.registercontent > .password,
.registercontent > .vcode {
  margin: 0 auto 15px;
  width: 460px;
  background: #ffffff;
  box-sizing: border-box;
}

.vcode {
  margin: 0 auto 28px;
  display: flex;
  align-items: center;
  width: 460px;
}

/deep/ .el-input__inner {
  height: 54px;
  line-height: 54px;
  font-size: 20px;
}

.loginsubmit {
  margin: 28px auto 0;
  width: 460px;
  font-size: 26px;
  background: #4e6ef2;
  color: #fff;
  height: 54px;
  line-height: 54px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}

.loginsubmit:hover {
  background: #3f63f3;
}

.agreement {
  margin-top: 36px;
}

.agreement > p {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: 600;
}

.agreement > p > span {
  color: #4e6ef2;
  cursor: pointer;
}

.phonelogin {
  position: absolute;
  right: 28px;
  bottom: 20px;
  width: 140px;
  height: 28px;
  font-family: MicrosoftYaHei;
  font-size: 20px;
  font-weight: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #4e6ef2;
  cursor: pointer;
}

.getvcode {
  box-sizing: border-box;
  margin-left: 10px;
  padding: 0 15px;
  font-size: 26px;
  background: #4e6ef2;
  color: #fff;
  height: 54px;
  white-space: nowrap;
  line-height: 54px;
  border-radius: 8px;
  cursor: pointer;
}

.footer {
  margin-top: 40px;
  font-family: Inter-Regular;
  letter-spacing: 0px;
  text-align: center;
  color: #000000;
}

.footer > p {
  margin: 0;
  font-size: 13px;
  font-weight: normal;
  height: 18px;
  line-height: 18px;
}
</style>
