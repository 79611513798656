import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login'
import Welcome from '../views/Welcome'

Vue.use(VueRouter)
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
    path: '/',
    name: '首页',
    components: {
        routerHome:()=>import ('@/Index'),
    },
    children:[
        {
            path: '/goIdentify',
            name: '我要识别',
            component: ()=>import ('@/views/Welcome'),
            meta: [{
                title: "我要识别"
            }]
        },
        {
            path: '/home',
            name: '我要识别',
            component: ()=>import ('@/views/Welcome'),
            meta: [{
                title: "我要识别"
            }]
        },
        {
            path: '/identifyPross',
            name: '我的识别',
            component: ()=>import ('@/views/IdentifyPross'),
            meta: [{
                title: "我的识别"
            }]
        },
        {
            path: '/myIdentify',
            name: '识别结果',
            component: ()=>import ('@/views/Identify'),
            meta: [{
                title: "识别结果"
            }]
        },
        {
            path: '/ipmanage',
            name: 'IPManage',
            component: () => import('@/views/IPManage.vue'),
            meta: [{
                title: 'IP管理'
            }]
        },
        {
            path: '/identdetails',
            name: 'IdentDetails',
            component: () => import('@/views/IdentificationDetails.vue'),
            meta: [
                {
                    title: '识别明细'
                }
            ]
        },
        {
            path: '/userList',
            name: '用户列表',
            component: ()=>import ('@/views/UserList'),
            meta: [{
                title: "用户列表"
            }]
        }
    ],
    meta: [{
        title: "登录"
    }]
},
{
    path: '/phoneUpPic',
    name: '移动端上传图片',
    components: {
        routerHome:()=>import ('@/views/PhoneUpPic'),
    },
    meta: [{
        title: "移动端上传图片"
    }]
},
{
    path: '/downContent',
    name: '获取识别结果',
    components: {
        routerHome:()=>import ('@/views/DownContent'),
    },
    meta: [{
        title: "获取识别结果"
    }]
},
{
    path: '/login',
    name: '登录页',
    components: {
        routerHome:()=>import ('@/views/Login'),
    },
    meta: [{
        title: "登录"
    }]
},
{
    path: '/proofreading/singlepagedetail',
    name: 'singlepagedetail',
    components: {
        routerHome:()=>import ('@/views/SinglePageDetail'),
    },
    meta: [{
        title: "单页校对详情"
    }]
},
]
const router = new VueRouter({
    routes
})

// 前置路由守卫
router.beforeEach((to, from, next) => {
    document.title = '两岸数字文献AI识别加工平台'
    next()
    
})

// 导航守卫，处理动态路由
let asyncRouter
export default router
